import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as metaActions from '../actions/meta.action';

import { tap } from 'rxjs/operators';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';

@Injectable()
export class MetaEffects {
  constructor(private actions$: Actions, private router: Router, private title: Title, private meta: Meta) {}

  setTitle$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(metaActions.SetTitle),
        tap(({ title }) => {
          this.title.setTitle(title);
        })
      );
    },
    { dispatch: false }
  );

  setMeta$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(metaActions.SetTags),
        tap(({ properties }) => {
          const keys = Object.keys(properties);
          const data: MetaDefinition[] = keys.map(a => {
            const property = a;
            const content = properties[a];

            return { property, content };
          });
          this.meta.addTags(data);
        })
      );
    },
    { dispatch: false }
  );
}
