import { createReducer, on } from '@ngrx/store';
import { TopbarConfig } from '../interfaces/topbar-config.model';
import * as NavActions from '../actions/nav.action';

export interface NavState {
  menuOpen: boolean;
  topbar?: TopbarConfig;
}

export const initialState: NavState = {
  menuOpen: false,
  topbar: null,
};

export const NavReducer = createReducer(
  initialState,
  on(NavActions.OpenMenu, state => {
    return {
      ...state,
      menuOpen: true,
    };
  }),
  on(NavActions.CloseMenu, state => {
    return {
      ...state,
      menuOpen: false,
    };
  }),
  on(NavActions.ToggleMenu, state => {
    return {
      ...state,
      menuOpen: !state.menuOpen,
    };
  }),
  on(NavActions.SetTopbarConfig, (state, { topbar }) => {
    return {
      ...state,
      topbar,
    };
  })
);

export const getMenuOpen = (state: NavState) => state.menuOpen;
export const getTopbarConfig = (state: NavState) => state.topbar;
