export function ToEntities(a: any[], key: string, initialEntities?: {}) {
  return a.reduce(
    (e: { [k: number]: any }, obj: any) => {
      return {
        ...e,
        [obj[key]]: { ...e[obj[key]], ...obj },
      };
    },
    {
      ...initialEntities,
    }
  );
}
