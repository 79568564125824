import { createReducer, on } from '@ngrx/store';
import * as ThemeActions from '../actions/theme.action';

export interface ThemeState {
  currentTheme: string;
  footerTheme: string;
}

export const initialState: ThemeState = {
  currentTheme: null,
  footerTheme: null,
};

export const ThemeReducer = createReducer(
  initialState,
  on(ThemeActions.SetTheme, (state, { theme }) => {
    return {
      ...state,
      currentTheme: theme,
    };
  }),
  on(ThemeActions.SetFooterTheme, (state, { theme }) => {
    return {
      ...state,
      footerTheme: theme,
    };
  })
);

export const getCurrentTheme = (state: ThemeState) => state.currentTheme;
export const getFooterTheme = (state: ThemeState) => state.footerTheme;
